import React from "react";
import { SEO, PageLayout, HeroElement, Content } from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class OrderOnlinePage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <div className={"hero"}>
              <h1>Order Online</h1>
              <HeroElement
                header={""}
                tagline={""}
                images={getPageHeroImage({
                  options: fishermanBusinessWebsitePage.components,
                })}
                ctas={[
                  {
                    internal: false,
                    text: "Order with ChowNow",
                    link: " http://t.yesware.com/tt/5384be07a7e64c3086464b52e019842002d3896d/a740d08861937993262c196ae3cb7f81/e57c04f7fbb8ab6724bc3dd64232d6c4/ordering.chownow.com/order/15484/locations/",
                  },
                  {
                    text: "Texas Hot Dogs iPhone App",
                    link: "https://apps.apple.com/us/app/texas-hot-dogs/id1475177563",
                    internal: false,
                  },
                  {
                    text: "ChowNow iPhone App",
                    link: "https://apps.apple.com/us/app/chownow/id1210943577",
                    internal: false,
                  },
                  {
                    text: "Texas Hot Dogs Android App",
                    link: "https://play.google.com/store/apps/details?id=com.chownow.texashotdogs&hl=en_US&gl=US",
                    internal: false,
                  },
                  {
                    text: "ChowNow Android App",
                    link: "https://play.google.com/store/apps/details?id=com.chownow.discover&hl=en_US&gl=US",
                    internal: false,
                  },
                ]}
                ctaInverted={true}
                ctaColor={"white"}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={false}
                containerAs={"none"}
                height={500}
              />
            </div>
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <Content container={true}>
                <Content.Markup isMarkdown={false}>
                  <Header as={"h1"} content={"Online Order - Coming Soon!"} />
                  <p>Please check back soon to order online.</p>
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Order Online" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
